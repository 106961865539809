;(function( window, document, $, undefined ) {
  'use strict';

  var app = (function() {

    var $private = {};
    var $public = {};
    var screen_width = $(window).width();

    // Responsive menu
    $public.responsiveMenu = function() {
      $('#dd-main-menu').slicknav({
        prependTo : '.responsive-nav',
        label : ''
      });
    };

    // Partner slider
    $public.partnersSlider = function() {

      // Desktop version
      function sliderPartnersDesktop() {
        var mainSlider = $('.dd-slider-partners').bxSlider({
          pager: false,
          controls: false,
          minSlides: 5,
          maxSlides: 5,
          moveSlides: 5,
          slideMargin: 26,
          slideWidth: 141,
          responsive: true,
          infiniteLoop: true,
          onSliderLoad : function() {
            $('.dd-slider-partners').css('visibility' , 'visible');

            if($(".dd-slider-partners li").length <= 5) {
              $('.dd-section-slider-footer .dd-small-arrow-slider').addClass('dd-hide');
            }
          }
        });

        $('.dd-section-slider-footer .dd-arrow-left').click(function(){
          mainSlider.goToPrevSlide();
        });

        $('.dd-section-slider-footer .dd-arrow-right').click(function(){
          mainSlider.goToNextSlide();
        });
      }// end Desktop version

      // Tablet version
      function sliderPartnersTablet() {
        var mainSlider = $('.dd-slider-partners').bxSlider({
          pager: false,
          controls: false,
          minSlides: 3,
          maxSlides: 3,
          moveSlides: 1,
          slideMargin: 13,
          slideWidth: 305,
          responsive: true,
          infiniteLoop: false,
          onSliderLoad : function() {
            $('.dd-slider-partners').css('visibility' , 'visible');

            if($(".dd-slider-partners li").length <= 3) {
              $('.dd-section-slider-footer .dd-small-arrow-slider').addClass('dd-hide');
            }
          }
        });

        $('.dd-section-slider-footer .dd-arrow-left').click(function(){
          mainSlider.goToPrevSlide();
        });

        $('.dd-section-slider-footer .dd-arrow-right').click(function(){
          mainSlider.goToNextSlide();
        });
      }// end Tablet version

      // Mobile version
      function sliderPartnersMobile() {
        var mainSlider = $('.dd-slider-partners').bxSlider({
          pager: false,
          controls: false,
          minSlides: 1,
          maxSlides: 1,
          moveSlides: 1,
          slideMargin: 13,
          responsive: true,
          infiniteLoop: false,
          onSliderLoad : function() {
            $('.dd-slider-partners').css('visibility' , 'visible');

            if($(".dd-slider-partners li").length == 1) {
              $('.dd-section-slider-footer .dd-small-arrow-slider').addClass('dd-hide');
            }
          }
        });

        $('.dd-section-slider-footer .dd-arrow-left').click(function(){
          mainSlider.goToPrevSlide();
        });

        $('.dd-section-slider-footer .dd-arrow-right').click(function(){
          mainSlider.goToNextSlide();
        });
      }// end Mobile version


      if (screen_width <= 640) {
        sliderPartnersMobile();
      }else if (screen_width >= 641 && screen_width <= 699){
        sliderPartnersTablet();
      }else if(screen_width >= 700){
        sliderPartnersDesktop();
      }
    }

    $public.formLoader = function() {
      $('form').submit(function(){

        var btnHasIcon = $(this).find('button i').length;

        if (!btnHasIcon) {
          $(this).find('button').addClass('dd-hide');
        };

        $('.dd-form-loader').removeClass('dd-hide').css('display', 'block');
      });
    }

    $public.maskInput = function() {
      $(".dd-f-phone").mask("(99) 9999-9999?9", {placeholder:" "});
      $(".dd-f-cpf").mask("999.999.999-99", {placeholder:" "});
      $(".dd-f-birthday").mask("99/99/9999", {placeholder:" "});
      $(".dd-f-rg").mask("99.999.999-9", {placeholder:" "});
    }

    $public.popUp = function() {
      // Se popup existe, mostre-o
      if( $('.dd-popup').length > 0 ) {
        $('.dd-popup').css('display','table').delay(2000).fadeIn();
      }

      // Se clicarmos fora do popup, também o fechamos
      $('.dd-popup').click(function() {
        $(this).fadeOut();
      });

      // Ação de fechar do botão em popup
      $('.dd-closeit').click(function(){
        $('.dd-popup').fadeOut();
      });
    }

    $public.eventSearch = function() {
      $( ".dd-sel-date" ).datepicker({
        dateFormat: 'dd-mm-yy',
        dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
        dayNamesMin: ['D','S','T','Q','Q','S','S','D'],
        dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb','Dom'],
        monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
        nextText: 'Próximo',
        prevText: 'Anterior',
        onSelect: function() {
          $('.dd-search-event').submit();
        }
      });

      $('.dd-sel-style').on('change', function(){
        $('.dd-search-event').submit();
      });
    }

    $public.titleHeight = function() {
      var maxHeight = 0;

      $(".dd-section-photo-list .dd-title").each(function(){
         if ($(this).height() > maxHeight) {
           maxHeight = $(this).height();
         }
      });

      $(".dd-section-photo-list .dd-title").css('min-height', maxHeight+'px');

    }

    $public.lightboxLanguage = function(){
      var hasLightbox = false;

      $('a').each(function(){
        var that = $(this).attr('data-lightbox');

        if (that) {
          lightbox.option({
            'albumLabel' : 'Imagem %1 de %2'
          });
        }
      });
    }

    $public.lightboxShare = function(){
      
      var htmlshare = '<div class="dd-share"><a itemprop="sameAs" href="" title="Facebook" class="dd-facebook dd-share-item"><i class="icon-facebook"></i></a></div>';
      var facebookshare = 'https://www.facebook.com/sharer/sharer.php?u=';
      var twittershare = 'https://twitter.com/share?url=';

      $('#lightbox .lb-details').append(htmlshare);

      $('.dd-share .dd-facebook').on("click", function (e) {
        e.preventDefault();

        var imageurl = $('.lb-container .lb-image').attr('src');

        console.log(imageurl);

        $(this).attr('href', facebookshare+imageurl);

        var t = 640, n = 300, r = screen.width / 2 - t / 2, i = screen.height / 2 - n / 2;
        window.open($(this).attr("href"), "popup", "width=" + t + ",height=" + n + ",top=" + i + ",left=" + r);
      });

    }


    return $public;
  })();

  // Global
  window.app = app;
  app.responsiveMenu();
  app.partnersSlider();
  app.formLoader();
  app.maskInput();
  app.popUp();
  app.eventSearch();
  app.titleHeight();
  app.lightboxShare();

})( window, document, jQuery );

$(window).load(function() {
  app.lightboxLanguage();
});
